class GoogleMap {
  constructor(el) {
    this.el = Array.from(document.querySelectorAll(el));
    
    if(this.el.length) {
      this.init();
    }
  }

  init() {
    this.el.forEach(el => {
      var data = el.getAttribute('data-map');
          data = data.split(";");

      var tempArr = [];
      data.forEach(data => {
        data = data.split(',');

        tempArr.push({
          address: data[0],
          zip: data[1],
          city: data[2],
          lat: parseFloat(data[3]),
          lng: parseFloat(data[4])
        });
      });

      data = {};
      data.locations = tempArr;
      data.el = el;

      this.initMap(data);
    });
  }

  initMap(data) {
    // Create map
    var map = new google.maps.Map(data.el, {
      center: {
        lat: 50.850346, 
        lng: 4.351721
      },
      zoom: 8,
      disableDefaultUI: true
    });

    // Add map bounds
    map.bounds = new google.maps.LatLngBounds();

    // Add markers
    this.addMapMarkers(map, data);
  }

  addMapMarkers(map, data) {
    data.locations.forEach(location => {
      // Create marker
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(location.lat, location.lng), 
        title: location.city,
        map: map
      });

      // Add infowindow
      this.addMarkerInfoWindow(map, marker, location);

      // Extend bounds
      map.bounds.extend(new google.maps.LatLng(location.lat, location.lng));
    });

    // Fit map to bounds
    map.fitBounds(map.bounds);
  }

  addMarkerInfoWindow(map, marker, location) {
    var infowindow = new google.maps.InfoWindow({
      content: `
      <div>
        ` + location.address + `<br>
        ` + location.zip + ` ` + location.city + `
      </div>
      `
    });

    marker.addListener('click', function() {
      infowindow.open(map, marker);
    });
  }
}

window.onload = () => {
  new GoogleMap('[data-map]');
}