class Tabs {
  constructor (el) {
    this.el = Array.from(document.querySelectorAll(el));

    if(this.el.length) {
      this.init();
    }
  }

  init() {
    this.el.forEach(el => {
      this.toggle(el);
    });
  }

  toggle(el) {
    el.onclick = () => {
      var id = el.getAttribute('href').replace('#', '');
      var content = document.getElementById(id);

      this.reset();

      el.classList.add('is-active');
      content.classList.add('is-active');

      return false;
    }
  }

  reset() {
    var activeTab = document.querySelector('[data-tab].is-active');
    var activeContent = document.querySelector('[data-tab-content].is-active');

    activeTab.classList.remove('is-active');
    activeContent.classList.remove('is-active');
  }
}

new Tabs('[data-tab]');