class Toggle {
  constructor(el) {
    this.el = Array.from(document.querySelectorAll(el));
    
    if(this.el.length) {
      this.init();
    }
  }

  init() {
    this.el.forEach(el => {
      this.toggle(el);
    });
  }

  toggle(el) {
    el.onclick = () => {
      var id = el.getAttribute('href').replace('#', '');
      var target = document.getElementById(id);

      if(el.classList.contains('is-active')) {
        el.classList.remove('is-active');
        target.classList.remove('is-active');
        document.querySelector('body').style.overflow = null;
      } else {
        el.classList.add('is-active');
        target.classList.add('is-active');
        document.querySelector('body').style.overflow = 'hidden';
      }

      return false;
    }
  }
}

new Toggle('[data-toggle]');